import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
} from "reactstrap"
import useWeatherDataQuery from "store/WeatherData/useWeatherDataQuery"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

const ForecastReport = () => {
  const [deviceId, setDeviceId] = useState("1012");
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const generateForecastDates = (days = 5) => {
    const today = new Date();
  
    return Array.from({ length: days }, (_, i) => {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      return nextDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    });
  };
  
  // Usage
  const dates = generateForecastDates();
  
  const {
    data: weatherForecastData,
    isSuccess,
    refetch,
  } = useWeatherDataQuery.weatherForecastData(deviceId);

  
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     refetch()
  //   }, 3600000)
  //   return () => clearInterval(interval)
  // }, []);

  const [data, setData] = useState({
    columns: [],
    rows: [],
  })

  const convert24To12 = time => {
    if (!time) return "--"
    const [sHours, minutes] = time.split(":")
    const hours = +sHours % 12 || 12
    const amPm = +sHours < 12 ? "AM" : "PM"
    return `${hours}:${minutes} ${amPm}`
  }

  const roundOff = num => {
    if (!num) return 0
    return num.toFixed(2)
  }
  const convertToKph = speed => {
    if (!speed && speed === 0) return 0
    return speed * 3.6
  }


  useEffect(() => {
    if (isSuccess) {
      const filteredData = weatherForecastData?.forecast?.filter(item => item.timestamp?.slice(0, 10) === selectedDate);
     
      const rows = filteredData?.map(item => {
        return {
          time: convert24To12(item.timestamp?.split("T")[1].split(".")[0]),
          rainFall: (item.rainfall),
          temperature: roundOff(item.temperature),
          humidity: roundOff(item.relative_humidity),
          pressure: roundOff(item.pressure),
          windSpeed: roundOff(item.wind_speed),
          direction: roundOff(item.wind_direction),
        }
      })

      setData({
        rows,
      })
    }
  }, [isSuccess, weatherForecastData, selectedDate]);

  document.title = "Forecast Report | AVG AWS"

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid" style={{ backgroundColor: '#E3FDF2' }}>
          <Breadcrumbs
            maintitle="AVG"
            title="Reports"
            breadcrumbItem="Forecast Report"
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="d-flex gap-2 align-items-top justify-content-between">
                    <CardTitle className="h4">Forecast Report</CardTitle>
                    
                    <FormGroup>
                      <Input
                        value={deviceId}
                        onChange={e => setDeviceId(e.target.value)}
                        type="select"
                        name="deviceId"
                        id="deviceId">
                        <option value={'1012'}>Weather 1</option>
                        <option value={'1013'}>Weather 2</option>
                      </Input>
                    </FormGroup>
                
                  </div>
                  <div className="row mt-3">
                    {dates?.map((date, index) => (
                      <div className="col" style={{ cursor: 'pointer' }} key={index} onClick={() => setSelectedDate(date)}>
                        <div className="card" style={{ borderRadius: '10px', border: '1px solid #E0E0E0', backgroundColor: selectedDate === date ? '#5DB996' : '#fff' }}>
                          <div className="card-body ">
                            <h5 className="card-title text-center" style={selectedDate === date ? { color: '#fff' } : {}}>{date?.split("-").reverse().join("-")}</h5>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="card" style={{ borderRadius: '10px', border: '1px solid #E0E0E0', backgroundColor: '#5DB996' }}>
                    <div className="card-body d-flex justify-content-between align-items-center">
                      <h5 className="card-title text-center text-white">Hourly Forecast Summary</h5>
                      <h5 className="card-title text-center text-white">{selectedDate?.split("-").reverse().join("-")}</h5>
                    </div>
                  </div>
                  <Row>
                    <Col className="col-12">
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <td>Time</td>
                              {data?.rows?.map((row, index) => (
                                <td key={index}>{row.time}</td>
                              ))}
                            </tr>
                            <tr>
                              <td>Rain Fall (mm)</td>
                              {data?.rows?.map((row, index) => (
                                <td key={index} style={{backgroundColor: '#5DB996', color: '#fff'}}>{row.rainFall}</td>
                              ))}
                            </tr>
                            <tr>
                              <td>Temperature (°C)</td>
                              {data?.rows?.map((row, index) => (
                                <td key={index}>{row.temperature}</td>
                              ))}
                            </tr>
                            <tr>
                              <td>Humidity (%) ˀ</td>
                              {data?.rows?.map((row, index) => (
                                <td key={index}>{row.humidity}</td>
                              ))}
                            </tr>
                            <tr>
                              <td>Pressure (hPa)</td>
                              {data?.rows?.map((row, index) => (
                                <td key={index}>{row.pressure}</td>
                              ))}
                            </tr>
                            <tr>
                              <td>Wind Speed (m/s)</td>
                              {data?.rows?.map((row, index) => (
                                <td key={index}>{row.windSpeed}</td>
                              ))}
                            </tr>
                            <tr>
                              <td>Direction</td>
                              {data?.rows?.map((row, index) => (
                                <td key={index}>{row.direction}</td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ForecastReport
